import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  width: 28px;
  height: 28px;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    path {
      fill: #161616;
    }
  }
`;

function SpotifyLogo() {
  return (
    <Svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2.33334C7.55524 2.33334 2.33334 7.55524 2.33334 14C2.33334 20.4448 7.55524 25.6667 14 25.6667C20.4448 25.6667 25.6667 20.4448 25.6667 14C25.6667 7.55524 20.4448 2.33334 14 2.33334ZM14 4.27779C19.4004 4.27779 23.7222 8.59962 23.7222 14C23.7222 19.4004 19.4004 23.7222 14 23.7222C8.59962 23.7222 4.27779 19.4004 4.27779 14C4.27779 8.59962 8.59962 4.27779 14 4.27779ZM12.4505 8.89584C10.5593 8.89584 8.91103 9.07054 7.34636 9.56425C6.93241 9.6478 6.58682 9.95921 6.58682 10.5365C6.58682 11.1137 7.01216 11.6226 7.58942 11.5391C7.83627 11.5391 8.00337 11.4479 8.16668 11.4479C9.48449 11.1175 10.9694 10.9618 12.4505 10.9618C15.3292 10.9618 18.2839 11.6036 20.2587 12.7544C20.5055 12.8379 20.5891 12.9366 20.8359 12.9366C21.4132 12.9366 21.8158 12.5113 21.8993 11.934C21.8993 11.4403 21.6525 11.1251 21.3221 10.9618C18.8535 9.56425 15.5761 8.89584 12.4505 8.89584ZM12.1771 12.5113C10.4491 12.5113 9.21865 12.7581 7.98439 13.0886C7.57423 13.2519 7.34636 13.5063 7.34636 14C7.34636 14.4102 7.67297 14.8203 8.16668 14.8203C8.33378 14.8203 8.40594 14.8127 8.65279 14.7292C9.55665 14.4823 10.7985 14.3342 12.1163 14.3342C14.8317 14.3342 17.1939 14.995 18.9219 16.066C19.0852 16.1495 19.2523 16.2179 19.4991 16.2179C19.9929 16.2179 20.3195 15.8001 20.3195 15.3064C20.3195 14.976 20.1637 14.6494 19.8333 14.4861C17.6952 13.1683 14.976 12.5113 12.1771 12.5113ZM12.4505 16.066C11.053 16.066 9.72755 16.2217 8.40973 16.5521C8.07933 16.6356 7.83248 16.8711 7.83248 17.2813C7.83248 17.6117 8.09072 17.9497 8.50088 17.9497C8.58443 17.9497 8.82369 17.8585 8.98699 17.8585C10.058 17.6117 11.2087 17.4636 12.3594 17.4636C14.414 17.4636 16.2938 17.9573 17.8585 18.8611C18.0256 18.9447 18.2117 19.013 18.375 19.013C18.7054 19.013 19.0206 18.7662 19.1042 18.4358C19.1042 18.0218 18.9257 17.8699 18.6788 17.7066C16.8673 16.6356 14.752 16.066 12.4505 16.066Z"
        fill="#888888"
      />
    </Svg>
  );
}

export default SpotifyLogo;
