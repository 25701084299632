import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  width: 28px;
  height: 28px;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    path {
      fill: #161616;
    }
  }
`;

function DribbleLogo() {
  return (
    <Svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2.33334C7.56702 2.33334 2.33331 7.56705 2.33331 14C2.33331 20.433 7.56702 25.6667 14 25.6667C20.4329 25.6667 25.6666 20.433 25.6666 14C25.6666 7.56705 20.4329 2.33334 14 2.33334ZM14 4.08334C16.4163 4.08334 18.6255 4.94454 20.3437 6.37452C18.738 7.78815 16.9019 8.93851 14.9217 9.82097C13.89 7.94477 12.7701 6.12546 11.5128 4.40919C12.3086 4.20415 13.1391 4.08334 14 4.08334ZM9.79132 5.0267C11.0819 6.73327 12.2206 8.55416 13.2685 10.4339C11.1128 11.1829 8.83031 11.6667 6.41665 11.6667C5.73552 11.6667 5.0641 11.6213 4.39777 11.5562C5.13151 8.65391 7.13469 6.27171 9.79132 5.0267ZM21.5845 7.61069C23.019 9.31288 23.8895 11.5044 23.9121 13.9054C22.6524 13.5895 21.3359 13.4167 19.9791 13.4167C18.9128 13.4167 17.8825 13.5543 16.8734 13.7516C16.5219 12.9374 16.1369 12.1461 15.7409 11.3568C17.8755 10.3915 19.8569 9.14448 21.5845 7.61069ZM14.1037 11.9994C14.4732 12.7293 14.8459 13.4539 15.1758 14.2062C11.6394 15.3123 8.60371 17.5576 6.56704 20.5557C5.0256 18.8085 4.08331 16.5196 4.08331 14C4.08331 13.7583 4.10287 13.5218 4.11977 13.2845C4.87536 13.362 5.63839 13.4167 6.41665 13.4167C9.12528 13.4167 11.693 12.8702 14.1037 11.9994ZM19.9791 15.1667C21.2909 15.1667 22.5565 15.353 23.764 15.6817C23.3062 18.3688 21.7794 20.6836 19.626 22.1678C19.1073 19.8712 18.3812 17.657 17.5501 15.4948C18.3517 15.3568 19.1374 15.1667 19.9791 15.1667ZM15.8035 15.8229C16.7199 18.1566 17.5036 20.5575 18.0286 23.0599C16.7975 23.6067 15.4366 23.9167 14 23.9167C11.6729 23.9167 9.54163 23.1132 7.85219 21.777C9.68849 18.9451 12.5013 16.824 15.8035 15.8229Z"
        fill="#888888"
      />
    </Svg>
  );
}

export default DribbleLogo;
