import React from "react";

import styled from "styled-components";
import DribbbleLogo from "../../components/dribbble";
import InstagramLogo from "../../components/instagram";
import LinkedinLogo from "../../components/linkedin";
import SpotifyLogo from "../../components/spotify";
import TwitterLogo from "../../components/twitter";

const PERSON_IMAGE = "/images/person.png";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
  max-width: 1224px;
  width: 100%;
  @media (max-width: 1330px) {
    padding: 0 24px;
  }
  @media (max-width: 950px) {
    padding: 16px;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 738px;
  width: 100%;
  margin-right: 108px;

  @media (max-width: 950px) {
    margin-right: initial;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  with: 100%;
`;

const Header = styled.h1`
  font-family: Recoleta;
  margin: 0 0 32px 0;
  font-size: 70px;
  @media (max-width: 1330px) {
    font-size: 60px;
  }
  @media (max-width: 1160px) {
    font-size: 50px;
  }
  @media (max-width: 640px) {
    font-size: 42px;
    margin: 0 0 24px 0;
  }
`;

const Text = styled.div`
  font-family: Karla;
  font-size: 24px;
  padding: 8px 0;
  @media (max-width: 1330px) {
    font-size: 22px;
  }
  @media (max-width: 1160px) {
    font-size: 20px;
  }
  @media (max-width: 640px) {
    font-size: 18px;
  }
`;

const Illustration = styled.img`
  width: 379px;
  @media (max-width: 950px) {
    width: 200px;
  }
  @media (max-width: 475px) {
    width: 150px;
  }
`;

const IconsWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  @media (max-width: 640px) {
    margin-top: 24px;
  }
`;

function Home() {
  return (
    <Wrapper>
      <LeftSide>
        <Header>
          Hello, <br />
          I'm Florence Chevalier.
        </Header>
        <Text>
          I’m a Product Designer & Maker creating meaningful products.
        </Text>
        <Text>
          I’m currently working full time on my my passion project{" "}
          <a href="https://okdog.app" target="_blank" rel="noreferrer">
            OK Dog,
          </a>{" "}
          while growing an inclusive community of sustainably focused digital
          nomads over at{" "}
          <a href="https://nomadsphere.io" target="_blank" rel="noreferrer">
            NomadSphere
          </a>{" "}
          .
        </Text>
        <Text>
          I’m presently based in Montreal, Canada (thanks Covid) but have been
          working remotely from different parts of the world since 2016.
        </Text>
        <Text>
          <a href="mailto:chevalier.florence@gmail.com">Say Hello</a> or lurk
          (I’ll make it easy for ya)
        </Text>
        <IconsWrapper>
          <a
            href="http://flowstateflo.dribbble.com/"
            target="_blank"
            rel="noreferrer"
          >
            <DribbbleLogo />
          </a>
          <a
            href="https://www.linkedin.com/in/florencechevalier/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinLogo />
          </a>
          <a
            href="https://www.instagram.com/flowstateflo/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramLogo />
          </a>
          <a
            href="https://twitter.com/flowstateflo"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterLogo />
          </a>
          <a
            href="https://open.spotify.com/user/flotography"
            target="_blank"
            rel="noreferrer"
          >
            <SpotifyLogo />
          </a>
        </IconsWrapper>
      </LeftSide>
      <RightSide>
        <Illustration src={PERSON_IMAGE} />
      </RightSide>
    </Wrapper>
  );
}

export default Home;
