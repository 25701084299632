import { useEffect } from "react";
import * as ReactGA from "react-ga";
import "./App.css";

import Home from "./containers/home";

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return <Home />;
}

export default App;
