import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  width: 28px;
  height: 28px;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    path {
      fill: #161616;
    }
  }
`;

function LinkedinLogo() {
  return (
    <Svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 3C4.13281 3 3 4.13281 3 5.5V22.5C3 23.8672 4.13281 25 5.5 25H22.5C23.8672 25 25 23.8672 25 22.5V5.5C25 4.13281 23.8672 3 22.5 3H5.5ZM5.5 5H22.5C22.7852 5 23 5.21484 23 5.5V22.5C23 22.7852 22.7852 23 22.5 23H5.5C5.21484 23 5 22.7852 5 22.5V5.5C5 5.21484 5.21484 5 5.5 5ZM8.4375 6.71875C7.48828 6.71875 6.71875 7.48828 6.71875 8.4375C6.71875 9.38672 7.48828 10.1563 8.4375 10.1563C9.38672 10.1563 10.1563 9.38672 10.1563 8.4375C10.1563 7.48828 9.38672 6.71875 8.4375 6.71875ZM17.4688 11.2813C16.0352 11.2813 15.082 12.0664 14.6875 12.8125H14.625V11.5H11.8125V21H14.75V16.3125C14.75 15.0742 14.9961 13.875 16.5313 13.875C18.043 13.875 18.0625 15.2734 18.0625 16.375V21H21V15.7813C21 13.2266 20.457 11.2813 17.4688 11.2813ZM7 11.5V21H9.96875V11.5H7Z"
        fill="#888888"
      />
    </Svg>
  );
}

export default LinkedinLogo;
