import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  width: 28px;
  height: 28px;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    path {
      fill: #161616;
    }
  }
`;

function InstagramLogo() {
  return (
    <Svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.46875 3C5.91797 3 3 5.91406 3 9.46875V18.5313C3 22.082 5.91406 25 9.46875 25H18.5313C22.082 25 25 22.0859 25 18.5313V9.46875C25 5.91797 22.0859 3 18.5313 3H9.46875ZM9.46875 5H18.5313C21.0039 5 23 6.99609 23 9.46875V18.5313C23 21.0039 21.0039 23 18.5313 23H9.46875C6.99609 23 5 21.0039 5 18.5313V9.46875C5 6.99609 6.99609 5 9.46875 5ZM19.9063 7.1875C19.4023 7.1875 19 7.58984 19 8.09375C19 8.59766 19.4023 9 19.9063 9C20.4102 9 20.8125 8.59766 20.8125 8.09375C20.8125 7.58984 20.4102 7.1875 19.9063 7.1875ZM14 8C10.6992 8 8 10.6992 8 14C8 17.3008 10.6992 20 14 20C17.3008 20 20 17.3008 20 14C20 10.6992 17.3008 8 14 8ZM14 10C16.2227 10 18 11.7773 18 14C18 16.2227 16.2227 18 14 18C11.7773 18 10 16.2227 10 14C10 11.7773 11.7773 10 14 10Z"
        fill="#888888"
      />
    </Svg>
  );
}

export default InstagramLogo;
